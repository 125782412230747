import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import('../views/HomeView.vue'),
    },
    {
        path: "/home",
        component: () => import('../views/HomeView.vue'),
    },
    {
        path: "/login",
        component: () => import('../views/LoginView.vue'),
    },
    {
        path: "/upload",
        component: () => import('../views/UploadView.vue'),
    },

]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory("/"),
    routes: routes,
    linkActiveClass: 'active'
})


export default router;

