import {createStore} from 'vuex'

const store = createStore({
    state() {
        return {
            authed_user: false
        }
    },
    mutations: {
        login(state) {
            state.authed_user = true
        },
        logout(state) {
            state.authed_user = false
        }
    },
    actions: {
        login(context) {
            // Perform login logic here...
            // Once login is successful, commit the 'login' mutation
            context.commit('login')
        },
        logout(context) {
            // Perform logout logic here...
            // Once logout is successful, commit the 'logout' mutation
            context.commit('logout')
        }
    },
    getters: {
        authed_user: state => state.authed_user
    }
})

export default store
